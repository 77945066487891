




































































































import { TimelineHorizontal } from '@/inc/types'
import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  /* eslint-disable */
  name: 'horizontal-timeline',
  props: {
    parentData: {
      type: Object,
      required: true,
    },
    items: {
      type: Array as () => TimelineHorizontal[],
      required: false,
    },
  },
  methods: {
    // methode permettant de gerer la barre d'avancement dans la timeline
    isActive(itemEtape, parentEtape) {
      var result = false
      if (itemEtape < parentEtape) {
        result = false
      }
      if (itemEtape == parentEtape) {
        result = true
      }
      if (parentEtape == '9') {
        result = false
      }
      return result
    },
    // permet de dire si l'etape est checked pour le logo ou non (logo rond bland)
    isChecked(itemEtape, parentEtape) {
      if (itemEtape < parentEtape) {
        return true
      }
      //TODO Last step a changer en const!
      if (parentEtape == '9') {
        return true
      }
      return false
    },
    isCloture(itemEtape) {
      if (itemEtape === '9') {
        return true
      }
      return false
    },
  },
  setup(_props, ctx) {
    const {i18nSuivi} = useState('my', ['i18nSuivi'])
    const ONE = ref('1')
    const TWO = ref('2')
    const THREE = ref('3')
    const FOUR = ref('4')
    const FIVE = ref('5')
    const SIX = ref('6')
    const SEVEN = ref('7')
    const EIGHT = ref('8')
    const NINE = ref('9')

    const timeline: TimelineHorizontal[] = [
      {
        title: i18nSuivi.value.reception,
        default: i18nSuivi.value.receptionDefault,
        etape: ONE.value,
        etat: [
          {text: i18nSuivi.value.receptionEtat11, statut: '11'},
          {text: i18nSuivi.value.receptionEtatO, statut: '0'},
        ],
      },
      {
        title: i18nSuivi.value.visite,
        default: i18nSuivi.value.visiteDefault,
        etape: TWO.value,
        etat: [
          {text: i18nSuivi.value.visiteEtat1, statut: '21'},
          {text: i18nSuivi.value.visiteEtat2, statut: '22'},
          {text: i18nSuivi.value.visiteEtat3, statut: '23'},
        ],
      },
      {
        title: i18nSuivi.value.analyse,
        default: i18nSuivi.value.analyseDefault,
        etape: THREE.value,
        etat: [
          {text: i18nSuivi.value.analyseEtat1, statut: '31'},
          {text: i18nSuivi.value.analyseEtat2, statut: '32'},
          {text: i18nSuivi.value.analyseEtat3, statut: '33'},
        ],
      },
      {
        title: i18nSuivi.value.devis,
        default: i18nSuivi.value.devisDefault,
        etape: FOUR.value,
        etat: [
          {text: i18nSuivi.value.devisEtat1, statut: '41'},
          {text: i18nSuivi.value.devisEtat2, statut: '42'},
          {text: i18nSuivi.value.devisEtat3, statut: '43'},
          {text: i18nSuivi.value.devisEtat4, statut: '44'},
          {text: i18nSuivi.value.devisEtat5, statut: '45'},
        ],
      },
      {
        title: i18nSuivi.value.autorisation,
        default: i18nSuivi.value.autorisationDefault,
        etape: FIVE.value,
        etat: [
          {text: i18nSuivi.value.autorisationEtat1, statut: '51'},
          {text: i18nSuivi.value.autorisationEtat2, statut: '52'},
        ],
      },
      {
        title: i18nSuivi.value.preparation,
        default: i18nSuivi.value.preparationDefault,
        etape: SIX.value,
        etat: [
          {text: i18nSuivi.value.preparationEtat1, statut: '61'},
          {text: i18nSuivi.value.preparationEtat2, statut: '62'},
        ],
      },
      {
        title: i18nSuivi.value.raccordement,
        default: i18nSuivi.value.raccordementDefault,
        etape: SEVEN.value,
        etat: [
          {text: i18nSuivi.value.raccordementEtat1, statut: '71'},
          {text: i18nSuivi.value.raccordementEtat2, statut: '72'},
          {text: i18nSuivi.value.raccordementEtat3, statut: '73'},
          {text: i18nSuivi.value.raccordementEtat4, statut: '74'},
        ],
      },
      {
        title: i18nSuivi.value.miseService,
        default: i18nSuivi.value.miseServiceDefault,
        etape: EIGHT.value,
        etat: [
          {text: i18nSuivi.value.miseServiceEtat1, statut: '81'},
          {text: i18nSuivi.value.miseServiceEtat2, statut: '82'},
          {text: i18nSuivi.value.miseServiceEtat3, statut: '83'},
          {text: i18nSuivi.value.miseServiceEtat4, statut: '84'},
          {text: i18nSuivi.value.miseServiceEtat5, statut: '85'},
        ],
      } /*
      {
        title: 'Cloture',
        default: 'Cloturer',
        etape: '9',
        etat: [{ text: 'Enregistrée', statut: '9' }],
      },*/,
    ]

    const toLowerCase = (string) => {
      return string.toLowerCase()
    }

    const myState = {
      ...useState('my', ['i18n']),
    }

    return {
      i18n: myState.i18n,
      i18nSuivi,
      timeline,
      toLowerCase,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
